import "./App.css";
import { Navigate, Outlet, redirect, useLocation, useNavigate } from "react-router-dom";
import { Col, Layout, Menu, Row, theme } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import logo from "./logo.svg";
import UserAvatar from "./common/UserAvatar";
import { useContext } from "react";
import { UserInfoContext } from "./common/FetchUserInfo";

function App() {
    const userInfo = useContext(UserInfoContext);
    const items = [
        {
            key: "0",
            label: "Veicoli",
            path: "/vehicles",
            permissions: ['vehicle.read']
        },
        {
            key: "2",
            label: "Promemoria manutenzioni",
            path: "/maintenanceReminders",
            permissions: ['maintenance.read']
        },
        {
            key: "3",
            label: "Piani di manutenzione",
            path: "/maintenancePlans",
            permissions: ['maintenance_plan.read']
        },
        {
            key: "4",
            label: "Tags",
            path: "/tags",
            permissions: []
        }
    ]
        .filter((x) => x.permissions.every((y) => userInfo.permissions?.includes(y) ?? false))
        .map((v, index) => ({ ...v, key: "" + index }));

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const navigate = useNavigate();
    const location = useLocation();

    const changePage = ({ key }) =>
        navigate(items.filter((x) => x.key === key)?.[0]?.path ?? "/vehicles");
    const key = items.filter((x) => location.pathname.startsWith(x.path))?.[0]?.key ?? "0";

    if (location.pathname === "/") return <Navigate to={"/vehicles"} replace />;

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Header
                style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        cursor: "pointer",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                        flexDirection: "row",
                        columnGap: 15,
                    }}
                    onClick={() => navigate("/vehicles", { replace: true })}
                >
                    <img alt="logo" style={{ height: 30 }} src={logo} />
                    <h2 style={{ margin: 0 }}>LAMBDA</h2>
                </div>
                <Menu
                    theme="dark"
                    mode="horizontal"
                    selectedKeys={[key]}
                    items={items}
                    onSelect={changePage}
                    style={{
                        flex: 1,
                        marginLeft: 70,
                        minWidth: 0,
                    }}
                />
                <UserAvatar />
            </Header>
            <Content
                style={{
                    padding: "0 6px",
                    margin: "16px 0",
                }}
            >
                <Row>
                    <Col
                        md={{ span: 24 }}
                        lg={{ span: 22, offset: 1 }}
                        style={{
                            padding: 24,
                            minHeight: 380,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        <Outlet />
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}

export default App;
