import { useContext, useEffect, useState } from "react";
import axiosInstance from "../api";
import { Button, Col, Form, Input, Modal, Row, Select, Space, Switch, Table } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BackTitle from "../common/BackTitle";
import { PlusOutlined } from "@ant-design/icons";
import { filterOption, withTooltip } from "../util";
import Toolbar from "../common/Toolbar";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { UserInfoContext } from "../common/FetchUserInfo";

const NewTagModal = ({ onOk, onCancel, visible }) => {
    const [form] = Form.useForm();
    const [vehicles, setVehicles] = useState([]);

    useEffect(() => {
        axiosInstance.get("vehicle", { pageSize: 9999 }).then(({ data }) =>
            setVehicles(
                data.data.map((x) => ({
                    label: `${x.label} - ${x.manufacturer} ${x.model}`,
                    value: x.id,
                })),
            ),
        );
    }, []);

    return (
        <Modal
            forceRender
            title="Nuovo tag"
            open={visible}
            okButtonProps={{ htmlType: "submit", form: "newTagForm" }}
            onCancel={() => {
                form.resetFields();
                onCancel?.();
            }}
        >
            <Form
                form={form}
                id="newTagForm"
                layout="vertical"
                onFinish={(data) => {
                    axiosInstance
                        .post("tag", data)
                        .then(() => {
                            onOk?.();
                        })
                        .then(() => form.resetFields());
                }}
            >
                <Form.Item label="ID" name={"id"}>
                    <Input />
                </Form.Item>
                <Form.Item label="Veicolo" name={"vehicleId"}>
                    <Select options={vehicles} showSearch allowClear />
                </Form.Item>

                <Form.Item label="Richiedi badge" initialValue={true} name="requireAuth">
                    <Switch />
                </Form.Item>
                <Form.Item label="Note" name="notes">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

const EditTagModal = ({ id, onOk, onCancel }) => {
    const [form] = Form.useForm();
    const [vehicles, setVehicles] = useState([]);

    useEffect(() => {
        if (id)
            axiosInstance
                .get(`tag/${id}`)
                .then(({ data }) => form.setFieldsValue({ ...data, vehicleId: data.vehicle?.id }));
        else form.resetFields();
    }, [id]);

    useEffect(() => {
        axiosInstance.get("vehicle", { pageSize: 9999 }).then(({ data }) =>
            setVehicles(
                data.data.map((x) => ({
                    label: `${x.label} - ${x.manufacturer} ${x.model}`,
                    value: x.id,
                })),
            ),
        );
    }, []);

    return (
        <Modal
            title="Modifica tag"
            forceRender
            open={!!id}
            okButtonProps={{ htmlType: "submit", form: "editTagForm" }}
            onCancel={() => {
                form.resetFields();
                onCancel?.();
            }}
        >
            <Form
                form={form}
                id="editTagForm"
                layout="vertical"
                onFinish={(data) => {
                    axiosInstance
                        .put(`tag/${id}`, data)
                        .then(() => {
                            onOk?.();
                        })
                        .then(() => form.resetFields());
                }}
            >
                <Form.Item label="ID" name={"id"}>
                    <Input disabled />
                </Form.Item>
                <Form.Item label="Veicolo" name={"vehicleId"}>
                    <Select options={vehicles} showSearch allowClear />
                </Form.Item>

                <Form.Item label="Richiedi badge" name="requireAuth">
                    <Switch />
                </Form.Item>
                <Form.Item label="Note" name="notes">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default function Tags() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedTag, setSelectedTag] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [newTagModalOpen, setNewTagModalOpen] = useState(false);
    const userPermissions = useContext(UserInfoContext)?.permissions ?? [];

    const columns = [
        {
            key: "id",
            dataIndex: "id",
            width: 70,
            ellipsis: true,
            title: "ID",
            render: withTooltip,
        },
        {
            key: "vehicle",
            dataIndex: "vehicle",
            width: 80,
            ellipsis: true,
            title: "Veicolo associato",
            render: (v) =>
                v && (
                    <Link to={`/vehicles/${v.id}`}>
                        {v.label} - {v.model}
                    </Link>
                ),
        },
        {
            key: "requireAuth",
            dataIndex: "requireAuth",
            width: 100,
            ellipsis: true,
            title: "Richiede Badge",
            render: (x) => (x ? "Si" : "No"),
        },
        {
            key: "notes",
            dataIndex: "notes",
            width: 100,
            ellipsis: true,
            title: "Note",
            render: withTooltip,
        },
    ];

    const loadData = () => {
        setLoading(true);
        setSelectedRowKeys([]);
        axiosInstance
            .get("tag")
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <NewTagModal
                visible={newTagModalOpen}
                onCancel={() => setNewTagModalOpen(false)}
                onOk={() => {
                    loadData();
                    setNewTagModalOpen(false);
                }}
            />
            <EditTagModal
                id={selectedTag}
                onCancel={() => setSelectedTag()}
                onOk={() => {
                    loadData();
                    setSelectedTag();
                }}
            ></EditTagModal>
            <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                <Row>
                    <Col span={12}>
                        <BackTitle backEnabled={false} title={"Tags"} padding={0} />
                    </Col>
                </Row>
                <Toolbar
                    selectedKeys={selectedRowKeys}
                    addAction={
                        userPermissions.includes("tag.create") && (() => setNewTagModalOpen(true))
                    }
                    editAction={
                        userPermissions.includes("tag.update") && ((key) => setSelectedTag(key))
                    }
                    deleteAction={
                        userPermissions.includes("tag.delete") &&
                        ((keys) => {
                            axiosInstance.delete(`tag/${keys[0]}`).then(loadData);
                        })
                    }
                />
                <Table
                    size="small"
                    loading={loading}
                    rowKey="id"
                    rowSelection={{
                        type: "radio",
                        selectedRowKeys: selectedRowKeys,
                        onChange: setSelectedRowKeys,
                    }}
                    columns={columns}
                    tableLayout={"fixed"}
                    scroll={{ x: columns.reduce((acc, x) => acc + x.width, 0) }}
                    dataSource={data?.map((x) => ({ ...x, key: x.id })) ?? []}
                />
            </div>
        </>
    );
}
